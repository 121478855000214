    // const firebaseConfig={

    
    //         apiKey: "AIzaSyBBsb-VN5Jx93ZLFg3ASGrXGAlol6JQcrA",
    //         authDomain: "crispaper-30459.firebaseapp.com",
    //         projectId: "crispaper-30459",
    //         storageBucket: "crispaper-30459.appspot.com",
    //         messagingSenderId: "1097049182617",
    //         appId: "1:1097049182617:web:0fae7692cf4bd5c43a0308",
    //         measurementId: "G-7GNKNR5G1Z"
        


    // }

const firebaseConfig={


    apiKey: "AIzaSyDMIbPGLuoOivuUXHzhqDjhntnKQaK0U0Y",
    authDomain: "crispaperprod.firebaseapp.com",
    projectId: "crispaperprod",
    storageBucket: "crispaperprod.appspot.com",
    messagingSenderId: "1033930940444",
    appId: "1:1033930940444:web:f572b86e86d5cbb4faffd9",
    measurementId: "G-K21W2ZR8E0"

}


export default firebaseConfig;